<template>
  <div
    v-if="tocItems.length"
    class="bg-primary-100 border-t-2 border-primary-600 mt-20 md:mt-30 xl:mt-40"
    :class="{ 'sticky bottom-0 z-50': !isEditing }"
  >
    <a
      href="#table-of-contents"
      class="pre-heading text-primary-700 py-20 lg:py-25 flex justify-between mobile-only:text-lg container"
      @click="onClick"
    >
      <span>{{ $texts('toc.title', 'Auf dieser Seite') }}</span>
      <SpriteSymbol
        v-show="!tocVisible"
        name="arrow-south"
        class="size-20"
        aria-hidden="true"
      />
    </a>
  </div>
  <div
    v-if="tocItems.length"
    id="table-of-contents"
    ref="tocEl"
    class="bg-primary-100 pb-20 lg:pb-30 mb-20 md:mb-30 xl:mb-40 -mt-1 scroll-mt-20"
  >
    <nav class="container">
      <ul
        class="grid grid-cols-1 md:grid-cols-3 auto-rows-auto gap-10 md:gap-20"
      >
        <li v-for="(item, key) in tocItems" :key="key">
          <a
            :href="'#' + item.link"
            class="link with-icon text-lg xl:text-xl text-balance"
          >
            <span>{{ item.text }}</span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script lang="ts" setup>
import type { FieldListItem } from '#blokkli/types'
import type {
  ParagraphFragment,
  ParagraphDynamicTeaserListFragment,
  ParagraphExpandSectionFragment,
  ParagraphFocusTeaserFragment,
  ParagraphFromLibraryFragment,
  ParagraphGalleryFragment,
  ParagraphPopularLinksFragment,
  ParagraphSectionTitleFragment,
  ParagraphTableFragment,
  ParagraphTeaserListFragment,
  ParagraphVideoRemoteFragment,
  ParagraphCsdServiceFragment,
} from '#graphql-operations'
import { useElementVisibility } from '@vueuse/core'
import { slugify } from '~/helpers/slugify'
import { falsy } from '~/helpers/type'
import { buildSectionTitle } from '~/helpers/dynamicTeaserList'

const tocEl = ref<HTMLDivElement | null>(null)
const { $texts } = useNuxtApp()

const tocVisible = useElementVisibility(tocEl)

function onClick(e: MouseEvent) {
  if (tocVisible.value) {
    e.preventDefault()
  }
}

type TocItem = {
  text: string
  link: string
}

type NarrowedParagraphItem<T extends ParagraphFragment> = FieldListItem & {
  props: T
}

const props = defineProps<{
  title?: string
  items?: TocItem[]
  paragraphItems?: FieldListItem[]
  isEditing?: boolean
}>()

const tocItems = computed(() => {
  if (props.paragraphItems) {
    return build(props.paragraphItems || []) || []
  }
  return props.items || []
})

// This type guard ensures that TS knows which ParagraphFragment we're actually working with.
// To keep things simple and the amount of type guard functions at a minimum, we're working with generics.
function hasFragmentType<T extends ParagraphFragment>(
  item: FieldListItem,
  desiredEntityBundle: string,
): item is NarrowedParagraphItem<T> {
  return item.bundle === desiredEntityBundle
}

function build(items: FieldListItem[]): TocItem[] {
  function getTocItem(
    item?: FieldListItem,
    overrideOptions?: Record<string, string>,
  ): string | undefined {
    if (!item || !('props' in item)) {
      return
    }

    const options: Record<string, string | number | boolean> =
      overrideOptions || item.options || {}

    if (
      hasFragmentType<ParagraphFromLibraryFragment>(item, 'from_library') &&
      item.props.libraryItem?.block
    ) {
      return getTocItem(item.props.libraryItem.block, item.options)
    }

    // By default the checkbox is enabled, so the value of the option is
    // either undefined, null or an empty string.
    // Only when the checkbox was explicitly unchecked is the value '0'
    // (or 0 when set by PHP because PHP).
    if (options.showInToc === '0' || options.showInToc === 0) {
      return
    }

    if (
      hasFragmentType<ParagraphExpandSectionFragment>(item, 'expand_section') ||
      hasFragmentType<ParagraphTableFragment>(item, 'table') ||
      hasFragmentType<ParagraphPopularLinksFragment>(item, 'popular_links') ||
      hasFragmentType<ParagraphVideoRemoteFragment>(item, 'video_remote') ||
      hasFragmentType<ParagraphGalleryFragment>(item, 'gallery') ||
      hasFragmentType<ParagraphFocusTeaserFragment>(item, 'focus_teaser')
    ) {
      return item.props.title
    }

    if (hasFragmentType<ParagraphTeaserListFragment>(item, 'teaser_list')) {
      return item.props.title || $texts('teaserListTitle', 'Aktuelles')
    }

    if (hasFragmentType<ParagraphSectionTitleFragment>(item, 'section_title')) {
      return item.props.anchor || item.props.title
    }

    // This check was added for some reason but it makes no sense.
    // It forces users to explicitly uncheck and check the checkbox in the
    // editor so that '1' is actually stored. Since the default value is true
    // (see comment above), this check here excludes a value of '', undefined
    // or null which would indicate "no value was set", which normally would
    // make the paragraph appear in the ToC.
    if (
      options.showInToc === '1' &&
      hasFragmentType<ParagraphCsdServiceFragment>(item, 'csd_service')
    ) {
      return item.props.csdService && 'name' in item.props.csdService
        ? item.props.csdService.name
        : undefined
    }

    if (hasFragmentType<ParagraphTeaserListFragment>(item, 'event_list')) {
      return item.props.title || $texts('events', 'Veranstaltungen')
    }

    if (
      hasFragmentType<ParagraphDynamicTeaserListFragment>(
        item,
        'dynamic_teaser_list',
      ) &&
      item.props.contentType
    ) {
      return buildSectionTitle(item.props.contentType)
    }
  }

  return items
    .map((v) => getTocItem(v))
    .filter(falsy)
    .map((v) => {
      return {
        text: v,
        link: slugify(v),
      }
    })
    .filter((v) => !!v.text)
}

defineOptions({
  name: 'TableOfContents',
})
</script>
